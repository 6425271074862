<template>
  <div :class="{ 'opacity-50': templateContractState == 'none' }">
    <div
      v-if="
        type == 'Basic' ||
        type == 'Community' ||
        type == 'Participation' ||
        type == 'Ticket' ||
        type == 'Role'
      "
      class="w-[240px] h-[376px] border-2 shadow-lg relative rounded-xl flex flex-col p-3"
      :class="{
        '!w-[184px] !h-[288px] !p-2': type != 'Role' && formatSm,
        '!w-[240px] !h-[240px]': type == 'Role' && !formatSm,
        '!w-[184px] !h-[184px] !p-2': type == 'Role' && formatSm,
        'border-secondary border-4': enableSelection && selected,
        'border-white border-4': enableSelection && !selected,
        'bg-[#241977] text-white': type == 'Basic',
        'bg-[#8280fc]  text-white': type == 'Community',
        'bg-[#f3f3ff]': type == 'Participation',
      }"
    >
      <div
        v-if="type != 'Ticket'"
        class="w-full h-full top-0 left-0 absolute flex items-end justify-end"
      >
        <img
          :src="
            type == 'Participation' || type == 'Ticket'
              ? require('/assets/images/mydid_watermark_black.png')
              : require('/assets/images/mydid_watermark_white.png')
          "
          alt=""
          class="w-[70px] opacity-15 pb-2"
          :class="
            type == 'Participation' || type == 'Ticket'
              ? 'opacity-[.07]'
              : 'opacity-15'
          "
        />
      </div>
      <div class="flex items-center mb-2">
        <img
          :src="currentIssuerInfo.profile.profileImage"
          alt=""
          class="w-7 h-7 rounded-full mr-1.5"
        />
        <p class="text-sm line-clamp-1">{{ issuerDisplayedName }}</p>

        <p v-if="!formatSm" class="flex-none text-xxs ml-auto">
          <icon
            :name="
              type == 'Basic'
                ? 'badge-basic'
                : type == 'Community'
                ? 'badge-community'
                : type == 'Participation'
                ? 'badge-participation'
                : type == 'Ticket'
                ? 'badge-ticket'
                : 'badge-role'
            "
            class="mr-0.5"
            :class="
              type == 'Participation' || type == 'Ticket' || type == 'Role'
                ? 'fill-black'
                : 'fill-white'
            "
          />{{ templateLabel }}
        </p>
      </div>
      <div v-if="type == 'Ticket'" class="my-2 px-1">
        <div class="border border-dashed"></div>
      </div>
      <div v-if="type != 'Ticket' && type != 'Role'">
        <div
          v-if="templateImage"
          class="w-full aspect-square overflow-hidden mb-2"
        >
          <img
            :src="templateImage"
            alt="badgePicture"
            class="object-cover rounded-lg overflow-hidden h-full w-full"
          />
        </div>
        <div
          v-else
          class="w-full border-gray-300 border rounded-xl aspect-square overflow-hidden mb-2"
        ></div>
      </div>
      <div v-if="type == 'Role'">
        <div
          v-if="templateImage && templateHexColor"
          class="w-full h-[100px] relative my-2"
          :class="{
            'h-[80px]': formatSm,
          }"
        >
          <div
            class="absolute top-0 left-[40%] left transform -translate-x-1/2 w-[80px] h-[80px] inline-flex rounded-full"
            :class="{
              'w-[60px] h-[60px]': formatSm,
            }"
            :style="`background-color:${templateHexColor}`"
          ></div>
          <div
            class="absolute top-0 left-[60%] transform -translate-x-1/2 w-[80px] aspect-square overflow-hidden"
            :class="{
              'w-[60px]': formatSm,
            }"
          >
            <img
              :src="templateImage"
              alt="badgePicture"
              class="object-cover rounded-lg overflow-hidden h-full w-full"
            />
          </div>
        </div>
        <div
          v-else
          class="w-full border-gray-300 border rounded-xl aspect-square overflow-hidden mb-2"
        ></div>
      </div>
      <div class="">
        <p
          class="text-left font-medium leading-tight line-clamp-1 mb-1.5"
          :class="{
            'text-md': !formatSm,
            'text-xs': formatSm,
            'text-center font-extrabold': type == 'Role',
          }"
        >
          {{ templateName }}
        </p>
        <p
          v-if="type == 'Participation' || type == 'Ticket'"
          class="text-left leading-tight line-clamp-1 mb-1.5"
          :class="!formatSm ? 'text-sm' : 'text-xs'"
        >
          {{ templateLocation ? templateLocation : '...' }}
        </p>
      </div>
      <div v-if="type == 'Ticket'">
        <div class="relative -m-3">
          <div class="absolute w-full h-full top-0 left-0 mt-3">
            <img
              :src="require('/assets/images/ticket-background-2.png')"
              alt=""
              class="w-full"
            />
          </div>
        </div>
        <div
          class="relative mt-6"
          :class="!formatSm ? 'h-[80.73px]' : 'h-[55px]'"
        >
          <div class="mb-2" :class="formatSm ? 'hidden' : ''">
            <p class="text-xxs text-gray-300 uppercase">
              {{ $t('templates.manageTicketTemplates.nameTxt') }}
            </p>
            <p class="text-xxs">--</p>
          </div>
          <div class="grid" :class="!formatSm ? 'grid-cols-4' : 'grid-cols-2'">
            <div :class="formatSm ? 'hidden' : ''">
              <p class="text-xxs text-gray-300 uppercase">
                {{ $t('templates.manageTicketTemplates.sectionTxt') }}
              </p>
              <p class="text-xxs">--</p>
            </div>
            <div :class="formatSm ? 'hidden' : ''">
              <p class="text-xxs text-gray-300 uppercase">
                {{ $t('templates.manageTicketTemplates.seatTxt') }}
              </p>
              <p class="text-xxs">--</p>
            </div>
            <div>
              <p class="text-xxs text-gray-300 uppercase">
                {{ $t('templates.manageTicketTemplates.dateTxt') }}
              </p>
              <p class="text-xxs">
                {{
                  new Date(templateStartDate)
                    .toLocaleDateString('en-GB', {
                      year: '2-digit',
                      month: '2-digit',
                      day: '2-digit',
                    })
                    .replace(/\//g, '.')
                }}
              </p>
            </div>
            <div>
              <p class="text-xxs text-gray-300 uppercase">
                {{ $t('templates.manageTicketTemplates.daysTxt') }}
              </p>
              <p class="text-xxs">
                {{
                  Math.ceil(
                    Math.abs(
                      new Date(templateEndDate) - new Date(templateStartDate)
                    ) /
                      (24 * 60 * 60 * 1000)
                  )
                }}
              </p>
            </div>
          </div>
        </div>
        <div class="relative -m-1.5 -mt-3">
          <div class="absolute w-full h-full top-0 left-0">
            <img
              :src="require('/assets/images/ticket-background.png')"
              alt=""
              class="w-full"
            />
          </div>
        </div>
        <div
          v-if="templateImage"
          class="relative aspect-square overflow-hidden mx-auto mt-3"
          :class="!formatSm ? 'w-[110px]' : 'w-[75px]'"
        >
          <img
            :src="templateImage"
            alt="badgePicture"
            class="object-cover rounded-lg bg-black overflow-hidden h-full w-full"
          />
        </div>
        <div
          v-else
          class="relative border-gray-200 bg-gray-100 border rounded-xl aspect-square overflow-hidden mx-auto mt-3"
          :class="!formatSm ? 'w-[110px]' : 'w-[70px]'"
        ></div>
      </div>
      <div class="grow"></div>
      <div v-if="templateSessions && type != 'Community'" class="flex">
        <div
          class="text-center bg-black rounded-md w-16 py-1"
          :class="{
            'bg-opacity-30': type == 'Basic',
            'bg-opacity-15': type == 'Community',
            'bg-opacity-[0.05]':
              type == 'Participation' || type == 'Ticket' || type == 'Role',
          }"
        >
          <p class="text-xs">
            <icon
              name="ongoing"
              class="mr-1.5 w-3"
              :class="
                type == 'Participation' || type == 'Ticket' || type == 'Role'
                  ? 'fill-black'
                  : 'fill-white'
              "
            />{{ ongoingSessions }}
          </p>
        </div>
        <div class="grow"></div>
        <div
          class="text-center bg-black rounded-md w-16 py-1"
          :class="{
            'bg-opacity-30': type == 'Basic',
            'bg-opacity-15': type == 'Community',
            'bg-opacity-[0.05]':
              type == 'Participation' || type == 'Ticket' || type == 'Role',
          }"
        >
          <p class="text-xs">
            {{ deliveredBadges }}
            {{
              deliveredBadges > 1 ? $t('other.ownersTxt') : $t('other.ownerTxt')
            }}
          </p>
        </div>
      </div>
    </div>
    <div
      v-if="type == 'Membership'"
      class="w-[240px] rounded-2xl relative flex items-center"
      :class="{
        'border-secondary border-4': enableSelection && selected,
        'border-transparent border-4': enableSelection && !selected,
        '!w-96 !rounded-2xl': formatXl,
      }"
    >
      <div
        v-if="templateImage"
        class="w-full aspect-[85.60/53.98] rounded-xl flex justify-center items-center overflow-hidden"
      >
        <img
          :src="templateImage"
          alt="badgePicture"
          class="object-cover min-h-full min-w-full"
        />
      </div>
      <div
        v-else
        class="w-full aspect-[85.60/53.98] bg-gray-light rounded-lg"
        :class="{
          '!w-96 !h-[245px]': formatXl,
          '!rounded-2xl': formatXl,
        }"
      ></div>
      <div
        class="absolute h-10 left-0 top-0 p-2"
        :class="{
          '!h-12': formatXl,
        }"
      >
        <div
          class="backdrop-blur-sm h-full inline-flex bg-[#050931] bg-opacity-40 rounded-md items-center px-2 py-1"
          :class="{
            'px-3 py-2': formatXl,
          }"
        >
          <p
            class="text-white font-medium text-xs"
            :class="{
              '!text-sm': formatXl,
            }"
          >
            {{ issuerDisplayedName }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, inject } from 'vue';
import { useI18n } from 'vue-i18n';
export default {
  props: {
    templateImage: String,
    templateName: String,
    templateStartDate: String,
    templateEndDate: String,
    templateLocation: String,
    templateHexColor: String,
    templateSessions: Array,
    templateContractState: String,
    issuerName: String,
    type: String,
    formatSm: Boolean,
    formatXl: Boolean,
    enableSelection: Boolean,
    selected: Boolean,
  },
  setup(props) {
    const utils = inject('utils');
    const store = inject('store');

    const templateLabel = ref(
      utils.templateTypeToLabel(props.type, useI18n(), true)
    );
    const currentIssuerInfo = computed(
      () => store.getters.getCurrentIssuerInfo
    );

    const ongoingSessions = computed(() => {
      if (!props.templateSessions) return null;

      return props.templateSessions.filter((session) => {
        if (session.didLimit != 1) return false;
        return session.didHistory.length == 0;
      }).length;
    });
    const deliveredBadges = computed(() => {
      if (!props.templateSessions) return null;

      return props.templateSessions.reduce((sum, session) => {
        return sum + session.didHistory.length;
      }, 0);
    });

    const issuerDisplayedName = computed(() => {
      return currentIssuerInfo.value.profile.name.length >= 20
        ? currentIssuerInfo.value.profile.name.substring(0, 17) + '...'
        : currentIssuerInfo.value.profile.name;
    });

    return {
      templateLabel,
      issuerDisplayedName,
      currentIssuerInfo,
      ongoingSessions,
      deliveredBadges,
    };
  },
};
</script>
