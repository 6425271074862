<template>
  <div class="py-10 px-16">
    <div class="flex items-center mb-10">
      <p class="text-xl font-bold">
        {{ $t(`templates.manage${type}Templates.title`) }}
      </p>
      <div class="grow"></div>
      <button
        v-if="
          ['Basic', 'Community', 'Participation', 'Ticket', 'Role'].includes(
            type
          )
        "
        class="text-sm bg-white border shadow-sm font-medium rounded-lg cursor-pointer py-1.5 px-2 mr-3"
        @click="
          (showTemplateCreation = true) &&
            (currentTemplateCreateTemplateData = null)
        "
        click-outside-ignore-templatecreation
      >
        <icon name="plus" class="mr-2" />{{
          $t(`templates.manage${type}Templates.createButton`)
        }}
      </button>
      <button
        v-if="
          ['Basic', 'Membership', 'Participation', 'Ticket', 'Role'].includes(
            type
          )
        "
        class="text-sm bg-secondary text-white shadow-sm font-medium rounded-lg cursor-pointer py-1.5 px-2"
        @click="
          () => {
            showTemplateSender = true;
            if (type == 'Membership')
              currentTemplateSenderTemplateData = templateList[0].data;
          }
        "
        click-outside-ignore-templatesender
      >
        <icon
          v-if="
            ['Basic', 'Community', 'Participation', 'Ticket'].includes(type)
          "
          name="paper-plane"
          class="fill-white mr-2"
        /><icon
          v-if="['Membership'].includes(type)"
          name="paper-plane"
          class="fill-white mr-2"
        />{{ $t(`templates.manage${type}Templates.sendButton`) }}
      </button>
    </div>
    <div
      v-if="
        ['Basic', 'Community', 'Participation', 'Ticket', 'Role'].includes(type)
      "
      class="flex border-b border-gray-600 border-opacity-20 w-full mb-10"
    >
      <p
        @click="selection = 'all'"
        class="pr-8 mr-2 cursor-pointer"
        :class="
          selection == 'all'
            ? 'border-b-2 border-black font-medium pb-4 '
            : 'text-gray-600'
        "
      >
        {{ $t(`templates.manageBasicTemplates.allSection`) }}
      </p>
      <p
        @click="selection = 'published'"
        class="pr-8 mr-2 cursor-pointer"
        :class="
          selection == 'published'
            ? 'border-b-2 border-black font-medium pb-4 '
            : 'text-gray-600'
        "
      >
        {{ $t(`templates.manageBasicTemplates.publishedSection`) }}
      </p>
      <p
        @click="selection = 'unpublished'"
        class="pr-8 cursor-pointer"
        :class="
          selection == 'unpublished'
            ? 'border-b-2 border-black font-medium pb-4 '
            : 'text-gray-600'
        "
      >
        {{ $t(`templates.manageBasicTemplates.unpublishedSection`) }}
      </p>
      <div v-if="['Participation', 'Ticket'].includes(type)" class="grow"></div>
    </div>
    <div class="flex -mt-5 mb-10">
      <p
        v-if="['Participation', 'Ticket'].includes(type)"
        @click="
          subSelection.indexOf('ongoing') != -1
            ? subSelection.splice(subSelection.indexOf('ongoing'), 1)
            : subSelection.push('ongoing')
        "
        class="mr-3 text-sm leading-1 px-4 py-0.5 rounded-lg cursor-pointer"
        :class="
          subSelection.indexOf('ongoing') != -1
            ? 'bg-blue-200 font-medium'
            : 'bg-gray-200 text-gray-600'
        "
      >
        {{ $t('templates.manageParticipationTemplates.ongoingSectionButton') }}
      </p>
      <p
        v-if="['Participation', 'Ticket'].includes(type)"
        @click="
          subSelection.indexOf('finished') != -1
            ? subSelection.splice(subSelection.indexOf('finished'), 1)
            : subSelection.push('finished')
        "
        class="text-sm leading-1 px-4 py-0.5 rounded-lg cursor-pointer"
        :class="
          subSelection.indexOf('finished') != -1
            ? 'bg-blue-200 font-medium'
            : 'bg-gray-200 text-gray-600'
        "
      >
        {{ $t('templates.manageParticipationTemplates.finishedSectionButton') }}
      </p>
    </div>

    <div class="flex flex-wrap gap-5 mb-10">
      <div
        v-if="!templateListLoading"
        v-for="(template, index) in templateList.filter((template) => {
          return (
            (['Participation', 'Ticket'].includes(type) &&
            subSelection.indexOf('ongoing') == -1
              ? new Date(template.data.eventDetails.endDate) <= new Date()
              : true) &&
            (['Participation', 'Ticket'].includes(type) &&
            subSelection.indexOf('finished') == -1
              ? new Date(template.data.eventDetails.endDate) > new Date()
              : true) &&
            (selection == 'published'
              ? template.contractState != 'none'
              : true) &&
            (selection == 'unpublished'
              ? template.contractState == 'none'
              : true)
          );
        })"
        :key="template.templateHash"
      >
        <TemplatePreview
          v-if="template.data && currentIssuerInfo"
          :templateImage="template.data.image"
          :templateName="template.data.name"
          :templateLocation="
            ['Participation', 'Ticket'].includes(type)
              ? template.data.eventDetails.location.split(', ')[0]
                ? template.data.eventDetails.location.split(', ')[0]
                : $t('templateDisplay.virtualTxt')
              : null
          "
          :templateStartDate="
            ['Participation', 'Ticket'].includes(type)
              ? template.data.eventDetails.startDate
              : null
          "
          :templateEndDate="
            ['Participation', 'Ticket'].includes(type)
              ? template.data.eventDetails.endDate
              : null
          "
          :templateHexColor="
            ['Role'].includes(type) ? template.data.hexColorRef : null
          "
          :templateSessions="template.sessions"
          :issuerName="currentIssuerInfo.profile.name"
          :templateContractState="template.contractState"
          :formatXl="type == 'Membership'"
          :type="type"
          @click="currentDisplayedTemplateIndex = index"
          class="cursor-pointer"
          click-outside-ignore-templatedisplay
        />
        <TemplateDisplay
          v-if="template.data && currentDisplayedTemplateIndex == index"
          :template="template"
          :type="type"
          :index="Number(template.index)"
          @close="currentDisplayedTemplateIndex = -1"
          @delete="
            () => {
              currentDisplayedTemplateIndex = -1;
              refreshTemplates();
            }
          "
          @send="
            () => {
              currentDisplayedTemplateIndex = -1;
              showTemplateSender = true;
              currentTemplateSenderTemplateData = template.data;
            }
          "
          @edit="
            () => {
              currentDisplayedTemplateIndex = -1;
              currentTemplateCreateTemplateData = template;
              showTemplateCreation = true;
            }
          "
          @clone="
            () => {
              currentDisplayedTemplateIndex = -1;
              currentTemplateCreateTemplateData = template;
              showTemplateCreation = true;
              isClone = true;
            }
          "
          @refresh="
            () => {
              refreshTemplates();
            }
          "
          click-outside-ignore-templatedisplay-target
        />
      </div>
      <div v-if="templateListLoading" class="w-full flex justify-center pt-10">
        <Vue3Lottie
          :animationLink="require('/assets/images/loader-round.jpg')"
          :height="100"
          :width="100"
          :speed="1.5"
        />
      </div>
      <div
        v-if="
          ['Basic', 'Community', 'Participation', 'Ticket', 'Role'].includes(
            type
          ) &&
          !templateListLoading &&
          templateList &&
          templateList.length == 0
        "
        class="mt-20 mx-auto"
      >
        <TemplateAddSection
          :type="type"
          @create="
            (showTemplateCreation = true) &&
              (currentTemplateCreateTemplateData = null)
          "
        ></TemplateAddSection>
      </div>
    </div>
    <div v-if="['Membership'].includes(type) && showOnboarding">
      <div class="fixed top-0 left-0 w-full h-full z-10">
        <div class="absolute w-full h-full bg-black bg-opacity-20"></div>
      </div>
      <BasicSmModal :noCloseOption="true">
        <p class="text-center text-2xl font-bold mb-4">
          {{ $t('templates.manageMembershipTemplates.onboardingTitle')
          }}<span class="text-3xl ml-2">🙌</span>
        </p>
        <p class="text-center text-gray-500 mb-4 px-10">
          {{ $t('templates.manageMembershipTemplates.onboardingText') }}
        </p>
        <div class="-mx-10 mb-8">
          <img
            :src="require('/assets/images/onboarding_cards.png')"
            alt=""
            class="w-full"
          />
        </div>
        <div class="flex justify-center mb-6">
          <button
            @click="handleOnboardingClick"
            class="bg-secondary text-white shadow-sm rounded-lg cursor-pointer py-1.5 px-2.5"
          >
            {{ $t('templates.manageMembershipTemplates.onboardingButton') }}
          </button>
        </div>
        <p class="text-center text-gray-500 mb-4 px-10">
          {{ $t('templates.manageMembershipTemplates.onboardingTextBis') }}
        </p>
      </BasicSmModal>
    </div>
    <TemplateCreate
      v-if="showTemplateCreation"
      :type="type"
      :noCloseOption="
        ['Membership'].includes(type) &&
        !templateListLoading &&
        templateList &&
        templateList.length == 0
      "
      :isClone="isClone"
      @close="
        (hash) => {
          showTemplateCreation = false;
          currentTemplateCreateTemplateData = null;
          handleTemplateHashFromClose(hash);
          isClone = false;
        }
      "
      @refresh="
        () => {
          refreshTemplates();
          currentTemplateCreateTemplateData = null;
        }
      "
      :templateData="currentTemplateCreateTemplateData"
      click-outside-ignore-templatecreation-target
    />
    <TemplateSend
      v-if="showTemplateSender"
      :type="type"
      @close="
        (hash) => {
          showTemplateSender = false;
          currentTemplateSenderTemplateData = null;
          if (hash) handleTemplateHashFromClose(hash);
        }
      "
      @reset="
        () => {
          currentTemplateSenderTemplateData = null;
        }
      "
      :templateList="templateList"
      :templateSelected="currentTemplateSenderTemplateData"
      click-outside-ignore-templatesender-target
    />
  </div>
</template>

<script>
import { ref, computed, watch, inject } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import TemplateDisplay from '../../components/templates/TemplateDisplay.vue';
import TemplatePreview from '../../components/templates/TemplatePreview.vue';
import TemplateCreate from '../../components/templates/TemplateCreate.vue';
import TemplateSend from '../../components/templates/TemplateSend.vue';
import TemplateAddSection from '../../components/templates/TemplateAddSection.vue';
import BasicSmModal from '../../components/BasicSmModal.vue';
export default {
  setup() {
    const utils = inject('utils');
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    // retrieve type
    const type = computed(() => {
      const pathType = route.path.split('/')[route.path.split('/').length - 1];
      return pathType[0].toUpperCase() + pathType.slice(1);
    });

    const currentIssuerInfo = computed(
      () => store.getters.getCurrentIssuerInfo
    );
    const templateList = computed(
      () => store.getters[`get${type.value}TemplateList`]
    );
    const templateListLoading = computed(
      () => store.getters.getTemplateListLoading
    );
    const isTourOngoing = computed(() => store.getters.isTourOngoing);

    const showOnboarding = ref(false);

    const currentDisplayedTemplateIndex = ref(-1);
    const showTemplateCreation = ref(false);
    const showTemplateSender = ref(false);
    const currentTemplateSenderTemplateData = ref(null);
    // const currentTemplateSenderTemplateData = computed(() =>
    //   templateList.value.length > 0 ? templateList.value[0].data : null
    // );
    const currentTemplateCreateTemplateData = ref(null);
    const forcedDisplayTemplateHash = ref(null);
    const selection = ref('all');
    const subSelection = ref(['ongoing', 'finished']);
    const isClone = ref(false);
    function handleRouteQuery() {
      if (route.query.add == '1') {
        showTemplateSender.value = false;
        showTemplateCreation.value = true;
        router.replace({ query: null });
      }
      if (route.query.send == '1') {
        if (
          ['Membership'].includes(type.value) &&
          !templateListLoading.value &&
          templateList.value &&
          templateList.value.length > 0
        )
          currentTemplateSenderTemplateData.value = templateList.value[0].data;
        showTemplateCreation.value = false;
        showTemplateSender.value = true;
        router.replace({ query: null });
      }
      if (route.query.hash) {
        if (!templateListLoading.value) {
          currentDisplayedTemplateIndex.value = templateList.value.findIndex(
            (template) =>
              template.templateHash.replace('0x', '') == route.query.hash
          );
        } else {
          forcedDisplayTemplateHash.value = route.query.hash;
        }
      }
    }
    handleRouteQuery();
    watch(route, handleRouteQuery);

    watch([templateListLoading, templateList], () => {
      if (
        !templateListLoading.value &&
        templateList.value.length > 0 &&
        forcedDisplayTemplateHash.value
      ) {
        currentDisplayedTemplateIndex.value = templateList.value.findIndex(
          (template) =>
            template.templateHash.replace('0x', '') ==
            forcedDisplayTemplateHash.value.replace('0x', '')
        );
        forcedDisplayTemplateHash.value = null;
      }
    });

    async function handleTemplateHashFromClose(hash) {
      if (!hash) return;
      if (!templateListLoading.value && templateList.value.length > 0) {
        currentDisplayedTemplateIndex.value = templateList.value.findIndex(
          (template) =>
            template.templateHash.replace('0x', '') == hash.replace('0x', '')
        );
      } else {
        forcedDisplayTemplateHash.value = hash.replace('0x', '');
      }
    }

    async function refreshTemplates() {
      store.dispatch('getTemplateLists');
    }

    function handleOnboardingClick() {
      showOnboarding.value = false;
      showTemplateCreation.value = true;
    }

    if (['Membership'].includes(type.value)) {
      function checkOnboarding() {
        if (
          !templateListLoading.value &&
          templateList.value &&
          templateList.value.length == 0 &&
          !isTourOngoing.value
        ) {
          showOnboarding.value = true;
        }
      }
      checkOnboarding();
      watch([templateListLoading, isTourOngoing], checkOnboarding);
    }

    return {
      type,
      showTemplateCreation,
      showTemplateSender,
      currentTemplateSenderTemplateData,
      currentTemplateCreateTemplateData,
      currentIssuerInfo,
      templateList,
      templateListLoading,
      currentDisplayedTemplateIndex,
      refreshTemplates,
      showOnboarding,
      handleOnboardingClick,
      forcedDisplayTemplateHash,
      handleTemplateHashFromClose,
      selection,
      subSelection,
      isClone,
    };
  },
  components: {
    TemplateDisplay,
    TemplatePreview,
    TemplateCreate,
    TemplateSend,
    TemplateAddSection,
    BasicSmModal,
  },
};
</script>
