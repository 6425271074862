import {
  generateSecretKey,
  finalizeEvent,
  SimplePool,
  Relay,
} from 'nostr-tools';
import EventsBus from './eventBus';
import ecies from 'electrum-ecies';
import secp256k1 from 'secp256k1';
import { base58btc } from 'multiformats/bases/base58';

const { emit } = EventsBus();
let reconnect = false;

export default {
  async install(app) {
    const pool = new SimplePool();
    const relays = process.env.VUE_APP_NOSTR_RELAYS.split(',');

    const nostr = {
      privateKey: null,
      publicKey: null,
      connectListener: null,
      userPublicKey: null,
      userListener: null,
      pingHandler: null,
      createSession: async () => {
        if (nostr.connectListener) nostr.connectListener.close();
        if (nostr.userListener) nostr.userListener.close();
        if (nostr.pingHandler) clearInterval(nostr.pingHandler);

        nostr.privateKey = null;
        nostr.publicKey = null;
        nostr.connectListener = null;
        nostr.userPublicKey = null;
        nostr.userListener = null;

        let tmpPrivateKey;
        let tmppublicKey;
        do {
          tmpPrivateKey = Buffer.from(generateSecretKey()).toString('hex');
          tmppublicKey = Buffer.from(
            secp256k1.publicKeyCreate(
              Uint8Array.from(
                Buffer.from(tmpPrivateKey.replace('0x', ''), 'hex')
              )
            )
          ).toString('hex');
          if (tmppublicKey.startsWith('02')) break;
        } while (true);

        nostr.privateKey = tmpPrivateKey;
        nostr.publicKey = tmppublicKey.substring(2);

        console.log('Create nostr session with public key :', nostr.publicKey);

        nostr.subscribeToConnexion();
      },
      subscribeToConnexion: () => {
        try {
          nostr.connectListener = pool.subscribeMany(
            [...relays],
            [
              {
                '#p': [nostr.publicKey],
                kinds: [30002],
                since: Date.now() / 1000,
              },
            ],
            {
              onevent(event) {
                // check event and trigger connexion
                const content = JSON.parse(event.content);
                try {
                  const data = ecies.decrypt(
                    base58btcToBase64(content.envelope.data),
                    nostr.privateKey
                  );

                  var decoder = new TextDecoder('utf-8');
                  const eventData = JSON.parse(decoder.decode(data));

                  console.log(
                    'Receive event data from receiver listener :',
                    eventData
                  );

                  emit('nostr', ['connect', eventData.message, event.pubkey]);
                } catch (e) {
                  console.log(e);
                }
              },
              oneose() {
                nostr.pingHandler = setInterval(
                  nostr.publishPingEvent,
                  process.env.VUE_APP_NOSTR_PING_PERIOD_S * 1000
                );
              },
              onclose(e) {
                if (e.includes('relay connection closed')) {
                  console.log('Socket closed by relay, reconnect triggered');
                  if (nostr.pingHandler) clearInterval(nostr.pingHandler);
                  nostr.subscribeToConnexion();
                }
              },
            }
          );
        } catch (e) {
          console('Error while subscribing to connexion with Nostr :', e);
        }
      },
      recoverSession: (privateKey, userPublicKey) => {
        nostr.privateKey = privateKey;
        nostr.userPublicKey = userPublicKey;

        nostr.publicKey = Buffer.from(
          secp256k1.publicKeyCreate(
            Uint8Array.from(
              Buffer.from(nostr.privateKey.replace('0x', ''), 'hex')
            )
          )
        )
          .toString('hex')
          .substring(2);

        console.log('Recover nostr session with public key :', nostr.publicKey);
        console.log('Recover nostr user public key :', nostr.userPublicKey);

        nostr.validateUser();
      },
      validateUser: (userPublicKey) => {
        if (nostr.connectListener) nostr.connectListener.close();
        if (nostr.pingHandler) clearInterval(nostr.pingHandler);

        if (userPublicKey) {
          nostr.userPublicKey = userPublicKey;
          console.log('Set nostr user public key :', nostr.userPublicKey);
        }

        nostr.subscribeToUser();
      },
      subscribeToUser: () => {
        try {
          nostr.userListener = pool.subscribeMany(
            [...relays],
            [
              {
                '#p': [nostr.publicKey],
                authors: [nostr.userPublicKey],
                kinds: [30003],
                since: Date.now() / 1000,
              },
            ],
            {
              onevent(event) {
                console.log('event received', event);
                // check request id
                let requestId = null;
                for (var tag of event.tags) {
                  if (tag[0] == 'r') requestId = tag[1];
                }

                // check event and trigger connexion
                const content = JSON.parse(event.content);
                try {
                  const data = ecies.decrypt(
                    base58btcToBase64(content.envelope.data),
                    nostr.privateKey
                  );

                  var decoder = new TextDecoder('utf-8');
                  const eventData = JSON.parse(decoder.decode(data));
                  console.log(
                    'Receive event data from user listener :',
                    eventData
                  );

                  emit('nostr', [
                    'user',
                    eventData.message,
                    eventData.type,
                    requestId,
                  ]);
                } catch (e) {
                  console.log(e);
                }
              },
              oneose() {
                nostr.pingHandler = setInterval(
                  nostr.publishPingEvent,
                  process.env.VUE_APP_NOSTR_PING_PERIOD_S * 1000
                );
              },
              onclose(e) {
                if (e.includes('relay connection closed')) {
                  console.log('Socket closed by relay, reconnect triggered');
                  if (nostr.pingHandler) clearInterval(nostr.pingHandler);
                  setTimeout(nostr.subscribeToUser, 2000);
                }
              },
            }
          );
        } catch (e) {
          console('Error while subscribing to user with Nostr :', e);
        }
      },
      publishEvent: async (type, message) => {
        const rawData = {
          type,
          message,
        };

        const envelope = {
          encryption: { algorithm: 'ECIES' },
          data: base64ToBase58btc(
            ecies.encrypt(JSON.stringify(rawData), '02' + nostr.userPublicKey)
          ),
        };

        const event = {
          kind: 30003,
          created_at: Math.floor(Date.now() / 1000),
          tags: [['p', nostr.userPublicKey]],
          content: JSON.stringify({ envelope }),
        };

        const signedEvent = finalizeEvent(event, nostr.privateKey);

        console.log('Trying to publish event :', type);
        const now = Date.now();

        await Promise.any(pool.publish(relays, signedEvent));

        console.log(
          'Publish event confirmed :',
          type,
          (Date.now() - now) / 1000
        );

        return signedEvent.id;
      },
      publishPingEvent: async () => {
        const event = {
          kind: 0,
          created_at: Math.floor(Date.now() / 1000),
          tags: [],
          content: 'ping',
        };

        const signedEvent = finalizeEvent(event, nostr.privateKey);

        try {
          await Promise.any(pool.publish(relays, signedEvent));
        } catch (e) {
          console.log(
            'Socket closed due to no network connexion, reconnect triggered'
          );
          if (nostr.pingHandler) clearInterval(nostr.pingHandler);
          setTimeout(nostr.subscribeToUser, 2000);
          reconnect = true;
          return signedEvent.id;
        }

        if (reconnect) {
          console.log(
            'Socket closed due to no network connexion, reconnect triggered (forced)'
          );
          if (nostr.pingHandler) clearInterval(nostr.pingHandler);
          setTimeout(nostr.subscribeToUser, 2000);
          reconnect = false;
        }

        return signedEvent.id;
      },
      requestSign: async (type, message) => {
        if (type == 'MultiSign')
          return await nostr.publishEvent('MultiSign', message);
      },
      pingApp: async () => {
        return nostr.publishEvent('Ping', {});
      },
      disconnectSession: async (noEvent) => {
        if (!noEvent) nostr.publishEvent('Disconnect', {});

        if (nostr.connectListener) nostr.connectListener.close();
        if (nostr.userListener) nostr.userListener.close();
        if (nostr.pingHandler) clearInterval(nostr.pingHandler);

        nostr.privateKey = null;
        nostr.publicKey = null;
        nostr.connectListener = null;
        nostr.userPublicKey = null;
        nostr.userListener = null;
      },
    };

    app.provide('nostr', nostr);
  },
};

function base58btcToBase64(base58btcString) {
  const byteArray = base58btc.decode(base58btcString);

  const buffer = Buffer.from(byteArray);

  const base64String = buffer.toString('base64');

  return base64String;
}

function base64ToBase58btc(base64String) {
  const byteArray = Buffer.from(base64String, 'base64');

  const base58btcString = base58btc.encode(byteArray);

  return base58btcString;
}
